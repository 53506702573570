import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import './partnerships.css'

const Partnerships = (props) => {
  return (
    <div className="partnerships-container">
      <Helmet>
        <title>Charge Collective - Welcome</title>
      </Helmet>
      <Navbar></Navbar>
      <header data-role="Header" className="partnerships-header">
        <Link to="/">
          <div className="partnerships-container1">
            <img
              alt="Logoremovebgpreview11278"
              src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/738e3011-d273-4dc5-ad59-39d2b6c8a4a5/808da9f3-d016-4ca7-b9c6-68775fa8e07d?org_if_sml=11791"
              className="partnerships-logoremovebgpreview1"
            />
            <span className="partnerships-text">
              <span>Charge Collective</span>
              <br></br>
            </span>
          </div>
        </Link>
        <div className="partnerships-container2">
          <div className="partnerships-nav"></div>
        </div>
        <div data-role="BurgerMenu" className="partnerships-burger-menu">
          <svg viewBox="0 0 1024 1024" className="partnerships-icon">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-role="MobileMenu" className="partnerships-mobile-menu">
          <div className="partnerships-nav1">
            <div className="partnerships-container3">
              <img
                alt="image"
                src="https://presentation-website-assets.teleporthq.io/logos/logo.png"
                className="partnerships-image"
              />
              <div
                data-role="CloseMobileMenu"
                className="partnerships-close-mobile-menu"
              >
                <svg viewBox="0 0 1024 1024" className="partnerships-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
          </div>
          <div>
            <svg
              viewBox="0 0 950.8571428571428 1024"
              className="partnerships-icon04"
            >
              <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
            </svg>
            <svg
              viewBox="0 0 877.7142857142857 1024"
              className="partnerships-icon06"
            >
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
            <svg
              viewBox="0 0 602.2582857142856 1024"
              className="partnerships-icon08"
            >
              <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
            </svg>
          </div>
        </div>
      </header>
      <div className="partnerships-hero">
        <div className="partnerships-container4">
          <h1 className="partnerships-text03">
            <span>
              Charge Collective &amp;
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="partnerships-text05">Your Business</span>
          </h1>
          <span className="partnerships-text06">
            <span>
              Looking to partner with us? Great! At Charge Collective,
              we&apos;re always on the lookout for innovative collaborations
              that can help advance our Purpose Driven Infrastructure mission.
              Whether you have a product or service that can add value to our
              Mobility Hub Solutions, or you&apos;re interested in exploring new
              opportunities for sustainable development and climate resilience,
              we&apos;re all ears.
            </span>
            <br></br>
            <br></br>
            <span>
              We partner with a wide range of organizations, including energy
              companies, asset managers, real estate developers, and tax equity
              investors, to turn underutilized spaces into vibrant and
              sustainable Mobility Hubs. Our hubs provide not only fast and
              accessible EV charging, but also a variety of lifestyle services
              that cater to the unique needs of each community we serve.
            </span>
            <br></br>
            <br></br>
            <span>
              Through our partnerships, we&apos;re able to leverage our
              regulatory, financial, and technical expertise to drive the
              development of green infrastructure and sustainable transportation
              solutions. We&apos;re also able to offer our partners unique
              opportunities for brand exposure and community engagement.
            </span>
            <br></br>
            <br></br>
            <span>
              So, whether you&apos;re a forward-thinking company looking to
              reduce your carbon footprint, a community organization seeking to
              promote clean energy and sustainability, or an investor interested
              in innovative and impactful infrastructure solutions, we&apos;d
              love to hear from you. Fill out the form below to get in touch
              with our business development team and let&apos;s explore how we
              can work together to build a better future.
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
        </div>
        <img
          alt="image"
          src="/playground_assets/render%20%231-600h.jpg"
          className="partnerships-image1"
        />
      </div>
      <div className="partnerships-hero1">
        <div className="partnerships-container5">
          <h1 className="partnerships-text19">
            <span>
              Charge Collective &amp;
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="partnerships-text21">
              Government and Non-Profit
            </span>
          </h1>
          <span className="partnerships-text22">
            <span>
              At Charge Collective, we believe in the power of collaboration and
              partnerships to create a more sustainable future for all.
              That&apos;s why we actively seek out partnerships with state,
              local, and federal government agencies, as well as non-profit
              organizations aligned with our mission.
            </span>
            <br></br>
            <br></br>
            <span>
              Through these partnerships, we are able to develop innovative
              solutions that address the unique challenges facing communities
              and accelerate the transition to a cleaner, more equitable future.
              Our workforce development programs help prepare workers for green
              jobs in the renewable energy and clean transportation industries,
              creating economic opportunities in underserved communities.
            </span>
            <br></br>
            <br></br>
            <span>
              We also work closely with local and national non-profits to design
              and implement climate resiliency programs that build community
              capacity to adapt to and mitigate the impacts of climate change.
              By partnering with us, these organizations can leverage our
              expertise in infrastructure, technology, and community engagement
              to create lasting impact and positive change.
            </span>
            <br></br>
            <br></br>
            <span>
              So whether you&apos;re a government agency, non-profit
              organization, or business looking to make a difference, we invite
              you to join us in building a more sustainable and equitable
              future. Let&apos;s partner together to create Purpose Driven
              Infrastructure that works for everyone.
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
        </div>
        <img
          alt="image"
          src="/playground_assets/render%20%233-800h.jpg"
          className="partnerships-image2"
        />
      </div>
      <center><iframe scrolling="no" height="610px" width="100%" src="https://opnform.com/forms/my-form-375"></iframe></center>
      <Footer rootClassName="footer-root-class-name2"></Footer>
    </div>
  )
}

export default Partnerships
