import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import AppComponent from '../components/component'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Charge Collective - Welcome</title>
        <meta property="og:title" content="Charge Collective - Welcome" />
      </Helmet>
      <Navbar></Navbar>
      <div className="home-banner">
        <span className="home-text">
          <span>
            Charge
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span className="home-text2">Collective</span>
        </span>
        <span className="home-text3">
          <span className="home-text4">
            Sustainability isn’t just a trend, it’s a way of life. As leaders in asset management, infrastructure orchestration and consumer services, we’re committed to powering the electrify everything movement through our
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span className="home-text09">
            purpose-driven
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span className="home-text6">infrastructure solutions.</span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </span>
        <AppComponent rootClassName="app-component-root-class-name"></AppComponent>
      </div>
      <Footer rootClassName="footer-root-class-name1"></Footer>
    </div>
  )
}

export default Home
