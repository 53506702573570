import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import './the-team.css'

const TheTeam = (props) => {
  return (
    <div className="the-team-container">
      <Helmet>
        <title>The-Team - Charge Collective - Welcome</title>
        <meta
          property="og:title"
          content="The-Team - Charge Collective - Welcome"
        />
      </Helmet>
      <Navbar></Navbar>
      <div className="the-team-hero">
        <div className="the-team-container1">
          <h1 className="the-team-text">
            <span>Our </span>
            <span className="the-team-text02">Founder.</span>
          </h1>
          <span className="the-team-text03">
            <a
              href="https://www.linkedin.com/in/jaguzik/"
              target="_blank"
              rel="noreferrer noopener"
              className="the-team-link"
            >
              Jon Alain Guzik
            </a>
            <span className="the-team-text04">
              {' '}
              is an impact entrepreneur and investor based in Los Angeles,
              California with a focus on renewable infrastructure and asset
              management, electrified transportation services and the
              intersection of mobility, fintech, cleantech and the built world.
            </span>
            <br className="the-team-text05"></br>
            <br className="the-team-text06"></br>
            <span className="the-team-text07">
              With a passion for the convergences in the automotive, consumer,
              and lifestyle spaces, Jon is dedicated to creating zero-emissions
              transportation solutions and equitable infrastructure for the
              electric vehicle ecosystem at scale. Previously, he served as the
              Founder and CTO at
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <a
              href="https://joinborrow.com"
              target="_blank"
              rel="noreferrer noopener"
              className="the-team-link1"
            >
              Borrow
            </a>
            <span className="the-team-text08">
              , the world’s first electric car subscription start-up and has
              been an advisor to numerous start-ups.
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
        </div>
        <img
          alt="image"
          src="https://images.squarespace-cdn.com/content/v1/57992c05e3df28ec6e348441/1494711708559-JZRYJDU1PZTLKJU7IA1L/Guz+Eagle.JPG?format=1500w"
          loading="lazy"
          className="the-team-image"
        />
      </div>
      <div className="the-team-hero1">
        <div className="the-team-container2">
          <h1 className="the-team-text11">
            <span>
              Our
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="the-team-text13">Team</span>
          </h1>
          <span className="the-team-text14">
            <span>
              At Charge Collective, we&apos;re passionate about creating
              purpose-driven infrastructure solutions that make a real
              difference. Our unique approach to asset management and technology
              orchestration is revolutionizing the EV infrastructure space with
              our nanogrid-powered Mobility Hub Solutions. Our Hubs provide DCFC
              and level 2 charging, making EVs more accessible for everyone.
            </span>
            <br></br>
            <br></br>
            <span>
              But we&apos;re more than just infrastructure. We&apos;re committed
              to climate-focused workforce development and resiliency programs.
              Our workforce development programs train workers for green jobs in
              renewable energy and clean transportation, creating opportunities
              for a more equitable and sustainable future.
            </span>
            <br></br>
            <br></br>
            <span>
              Our resiliency programs help communities prepare for and respond
              to the impacts of climate change. By building resilience at the
              local level, we can ensure that communities are better prepared
              for the challenges of a changing climate. We tailor our programs
              to the specific needs of each community, whether that means
              designing green infrastructure, partnering with local
              organizations for training, or supporting local businesses with
              sustainable practices.
            </span>
            <br></br>
            <br></br>
            <span>
              At Charge Collective, we&apos;re excited about the positive impact
              our solutions are having on the environment and the communities we
              serve. Join us in creating a better future with purpose-driven
              infrastructure. We&apos;re hiring, so drop us a line.
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
        </div>
        <img
          alt="image"
          src="/playground_assets/render%20%234-1500w.jpg"
          className="the-team-image1"
        />
      </div>
      <Footer></Footer>
    </div>
  )
}

export default TheTeam
