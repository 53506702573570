import React from 'react'

import PropTypes from 'prop-types'

import './component.css'

const AppComponent = (props) => {
  return (
    <div className={`component-container ${props.rootClassName} `}>
      <a
        href="mailto:hello@chargecollective.com?subject="
        className="component-link"
      >
        <div className="component-container1">
          <button type="button" className="component-button button">
            <span className="component-text button">
              <span className="">Say Hi</span>
              <span className=""> 👋</span>
            </span>
          </button>
        </div>
      </a>
    </div>
  )
}

AppComponent.defaultProps = {
  rootClassName: '',
}

AppComponent.propTypes = {
  rootClassName: PropTypes.string,
}

export default AppComponent
