import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import './about.css'

const About = (props) => {
  return (
    <div className="about-container">
      <Helmet>
        <title>About - Charge Collective - Welcome</title>
        <meta
          property="og:title"
          content="About - Charge Collective - Welcome"
        />
      </Helmet>
      <Navbar></Navbar>
      <div className="about-hero">
        <div className="about-container1">
          <h1 className="about-text">
            <span>Our </span>
            <span className="about-text02">Mission.</span>
          </h1>
          <span className="about-text03">
            <span>
              Charge Collective is
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="about-text05">changing the game</span>
            <span>
              {' '}
              when it comes to electric vehicle infrastructure.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <br></br>
            <span>
              We&apos;re not just about charging stations - we&apos;re all about
              creating community hubs where people can come together and enjoy a
              variety of lifestyle services. And we do it all using our
              proprietary grid-resilient microgrids and some serious know-how in
              asset management, project development, and infrastructure
              resources.
            </span>
            <br></br>
            <br></br>
            <span>
              We&apos;re not in this alone, though. We partner with everyone
              from energy companies to tax equity investors to turn underused
              spaces into thriving Charge Collectives. And we&apos;re not just
              focused on urban areas - we want to bring our solution to
              underserved and disadvantaged communities everywhere.
            </span>
            <br></br>
            <br></br>
            <span>
              Scaling our Purpose Driven Infrastructure solution is a piece of
              cake thanks to our go-to-market stack. With our regulatory,
              partnership, financial, political, governmental, and technical
              expertise, we can grow our solution in an asset-light manner while
              generating revenue from our platform and asset and financial
              management fees.
            </span>
            <br></br>
            <br></br>
            <Link to="/partnerships" className="about-navlink">
              Join us
            </Link>
            <span>
              {' '}
              on our mission to create a better, cleaner, and more equitable
              future!
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
        </div>
        <img
          alt="image"
          src="/playground_assets/render%20%232-1500w.jpg"
          className="about-image"
        />
      </div>
      <Footer rootClassName="footer-root-class-name"></Footer>
    </div>
  )
}

export default About
