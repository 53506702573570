import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './home-nav.css'

const HomeNav = (props) => {
  return (
    <nav className={`home-nav-nav ${props.rootClassName} `}>
      <Link to="/" className="home-nav-navlink">
        {props.text5}
      </Link>
      <Link to="/about" className="home-nav-navlink1">
        {props.text11}
      </Link>
      <Link to="/the-team" className="home-nav-navlink2">
        {props.text111}
      </Link>
      <Link to="/partnerships" className="home-nav-navlink3">
        {props.text21}
      </Link>
    </nav>
  )
}

HomeNav.defaultProps = {
  text21: 'Partnerships',
  text5: 'Home',
  text11: 'About',
  rootClassName: '',
  text111: 'The Team',
}

HomeNav.propTypes = {
  text21: PropTypes.string,
  text5: PropTypes.string,
  text11: PropTypes.string,
  rootClassName: PropTypes.string,
  text111: PropTypes.string,
}

export default HomeNav
