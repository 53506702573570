import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import HomeNav from './home-nav'
import './navbar.css'

const Navbar = (props) => {
  return (
    <div className="navbar-navbar">
      <header data-thq="thq-navbar" className="navbar-navbar-interactive">
        <Link to="/" className="navbar-navlink">
          <div className="navbar-container">
            <div className="navbar-container1">
              <img
                alt={props.image_alt}
                src={props.image_src}
                className="navbar-image"
              />
            </div>
            <span className="navbar-text">
              <span>Charge Collective</span>
              <br></br>
            </span>
          </div>
        </Link>
        <div
          data-thq="thq-navbar-nav"
          data-role="Nav"
          className="navbar-desktop-menu"
        >
          <nav
            data-thq="thq-navbar-nav-links"
            data-role="Nav"
            className="navbar-nav"
          >
            <HomeNav rootClassName="home-nav-root-class-name9"></HomeNav>
          </nav>
        </div>
        <div data-thq="thq-burger-menu" className="navbar-burger-menu">
          <svg viewBox="0 0 1024 1024" className="navbar-icon">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="navbar-mobile-menu">
          <div
            data-thq="thq-mobile-menu-nav"
            data-role="Nav"
            className="navbar-nav1"
          >
            <div className="navbar-container2">
              <div data-thq="thq-close-menu" className="navbar-menu-close">
                <svg viewBox="0 0 1024 1024" className="navbar-icon2">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <Link to="/" className="navbar-navlink1">
              <div className="navbar-container3">
                <img
                  alt={props.Logoremovebgpreview1_alt1}
                  src={props.Logoremovebgpreview1_src1}
                  className="navbar-logoremovebgpreview1"
                />
              </div>
            </Link>
            <nav
              data-thq="thq-mobile-menu-nav-links"
              data-role="Nav"
              className="navbar-nav2"
            >
              <HomeNav rootClassName="home-nav-root-class-name10"></HomeNav>
            </nav>
          </div>
        </div>
      </header>
    </div>
  )
}

Navbar.defaultProps = {
  Logoremovebgpreview1_src1:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/738e3011-d273-4dc5-ad59-39d2b6c8a4a5/808da9f3-d016-4ca7-b9c6-68775fa8e07d?org_if_sml=11791',
  image_src: '/playground_assets/cc_purple-200h.png',
  Logoremovebgpreview1_src:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/738e3011-d273-4dc5-ad59-39d2b6c8a4a5/808da9f3-d016-4ca7-b9c6-68775fa8e07d?org_if_sml=11791',
  Logoremovebgpreview1_alt1: 'Logoremovebgpreview11278',
  image_alt: 'image',
  Logoremovebgpreview1_alt: 'Logoremovebgpreview11278',
}

Navbar.propTypes = {
  Logoremovebgpreview1_src1: PropTypes.string,
  image_src: PropTypes.string,
  Logoremovebgpreview1_src: PropTypes.string,
  Logoremovebgpreview1_alt1: PropTypes.string,
  image_alt: PropTypes.string,
  Logoremovebgpreview1_alt: PropTypes.string,
}

export default Navbar
